<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ isEdit ? 'Editar' : 'Agregar nueva' }} Especialidad</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click=";[hide(), resetForm()]"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->

        <b-form class="p-2" @submit.prevent="onSubmit(items)">
          <validation-provider #default="{ errors }" name="project" rules="requeridoE">
            <b-form-group label="Proyecto" label-for="project">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="records"
                label="code"
                input-id="project"
                :reduce="(records) => records.id"
                placeholder="Proyecto"
                v-model="items.project_id"
                class="select-obra"
                disabled
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="code" rules="requeridoE">
            <b-form-group label="Codigo" label-for="code">
              <b-form-input
                id="code"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Codigo"
                v-model="items.code"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="description" rules="">
            <b-form-group label="Descripcion" label-for="description">
              <b-form-input
                id="description"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Descripcion"
                v-model="items.description"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>{{ isEdit ? 'Actualizar' : 'Agregar' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click=";[hide(), resetForm()]"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ProjectsService from '@/services/ProjectsService'
import SpecialityService from '@/services/SpecialityService'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      // speciality: {
      //   title: ''
      // },
      isEdit: false,
      isDisabled: false,
      records: [],
      items: {
        project_id: JSON.parse(localStorage.getItem('project_id')),
        code: '',
        description: ''
      },
      temp: {}
    }
  },
  mounted() {
    this.getProjects()
  },
  methods: {
    async getProjects() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos.status) {
        this.records = respProyectos.data.rows
      }
    },
    setData(items) {
      if (Object.entries(items).length !== 0) {
        this.temp = items
        this.items = items
        this.isEdit = true
      } else {
        this.temp = {}
        this.items = {
          project_id: JSON.parse(localStorage.getItem('project_id')),
          code: '',
          description: ''
        }
        this.isEdit = false
      }
      console.log('temp EN ADD', this.temp)
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.items = {
        company: null,
        name: '',
        responsableName: '',
        responsableEmail: '',
        responsableEmailSeconday: ''
      }
    },
    async onSubmit(data) {
      console.log('data', data)
      console.log('data', data)
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.isDisabled = true
          let resp = ''
          let datos = {}
          datos.project_id = data.project_id
          datos.code = data.code
          datos.description = data.description
          if (this.isEdit == false) {
            resp = await SpecialityService.saveSpeciality(datos, this.$store)
          } else {
            let id = data.id
            resp = await SpecialityService.updateSpeciality(id, datos, this.$store)
          }
          console.log('datos', datos)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text:
                'La especialidad ha sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            console.log('this.$parent.$parent', this.$parent.$parent)
            this.$parent.$parent.$parent.getData()
            this.$emit('update:is-add', false)
            this.resetForm()
          } else {
            this.$swal({
              title: 'Error!',
              text:
                ' Hubo un error al ' +
                (this.isEdit == true ? 'actualizar' : 'registrar') +
                ' la especialidad',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        } else {
          console.log('no validado')
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
